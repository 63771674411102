
.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

    .separator::before, .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid darkgray;
    }

    .separator::before {
        margin-right: .25em;
    }

    .separator::after {
        margin-left: .25em;
    }
