.confirmButton {
    height: 50px;
    margin-top: 20px;
    width: 100%;
}

.appBar {
    position: relative;
}

.dialog {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0;
    height: 100%;
    margin: 0;
    max-height: none;
    max-width: 100%;
    width: 100%;
}

.toolBar {
    justify-content: center;
    padding-right: 0;
}

.dialogContainer {
    margin: 20px;
    text-align: center;
}

.buttonContainer {
    /*height: 220px;*/
    height: auto;
}

.largeButton {
    /*height: 50px;
    margin-top: 20px;
    width: 80%;
    */

    /*DH margin: 20px;
    width: 240px; */

    height: 50px;
    margin-top: 20px;
    width: 100%;
}

.noMealButton {
    height: 50px;
    margin-top: 10px;
    width: 100%;
}

    .noMealButton:hover {
        background-color: #6b0404;
    }

