/* OrderMenu */

.tab { margin-top: 30px; }

.tabLabel {
    font-weight: 400;
    text-transform: none;
}

.tabLabelBold {
    font-weight: 800;
    text-transform: none;
}

.header {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 6px;
}

#headerContent { margin-right: 5px; }

.information {
    align-items: center;
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
}

.informationText {
    text-align: left;
}

.informationText::first-letter {
    text-transform: capitalize;
}

.icon {
    margin-right: 10px;
}

.container { margin: 10px; }

.snackbar {
    align-items: center;
    display: flex;
    text-align: center;
}

.errorIcon {
    margin-right: 20px;
}

.warningIcon { 
    margin-right: 20px; 
}

.successIcon {
    margin-right: 20px;
}

.diet {
    display: flex;
}

.dialog {
    margin: 15px;
    width: 450px;
    /*width: 80%;*/
}

.dividerFullWidth {
    padding: 0 0 2px 2px ;
}

.nutrientDividerFullWidth {
    padding: 0 0 2px 2px;
}

.foodInformationImage {
    max-height: 150px;
    min-width: 200px;
    overflow: hidden
}

.foodDialogTitle {
    font-weight:bold;
}

.foodDialogClose {
    margin-left: auto;
}

.detailedDescription {
    padding: 5px 0 5px 0;
    display: block;
    word-wrap: break-word;
    white-space: pre-wrap;
}