/* Profile */

.formControl {
    min-width: 120px;
    text-transform: capitalize;
}

.welcome {
    /*margin-top: 15px;*/
}



.settingsList {
    margin: 10px;
}

.dietItem {
    text-transform: capitalize;
}

.languageItem {
    text-transform: capitalize;
}


.userContainer {
    display: flex;
    align-items: center;
    padding: 15px 15px 0 0;
}

.patientText {
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}
.avatar {
}

.navigateIcon {
    margin-top: -10px;
    transform: rotate(90deg);
}

.guestEater {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    text-transform: none;
}

.guestEaterName {
    max-width: 80px;
    text-transform: none;
    color: initial;
}

.guestEaterContainer {
    display: flex;
    justify-content: space-between;
}

.divMargin {
    padding-bottom: 15px;
}

.dialog {
    margin: 15px;
    max-width: 450px;
}

.actualDietContainer {
    padding-top: 15px;
    padding-right: 0;
    padding-bottom: 15px;
    padding-left: 15px;
}