.container { margin-top: 20px; }

.foodContainer {
    margin-top: 10px;
    width: 100%;
}

#button {
    height: 50px;
    margin-top: 10px;
    width: 240px;
}

.miniIcon {
    margin: 7px;
    min-width: 34px;
    height: 34px;
    width: 34px;
}

.actionsContainer {
    align-items: center;
    display: flex;
}

.summary { text-align: left; }

#counter {
    text-align: center;
    width: 27px; /*DH van 20px naar 27px voor 0,25*/
}

.listItem {
    padding: 0;
    display: flex;
    /*justify-content: flex-end;*/
}

.panelHeader {
    background-color: #fbfbfb; /* color can stay hardcoded */
    box-shadow: 0 4px 2px -2px #dedcdc;
}

.circleNumber {
    font-size: 1rem;
    height: 25px;
    margin-right: 10px;
    width: 25px;
}

.courseTitle {
    font-size: 1.2rem;
    text-transform: capitalize;
}

#list {
    margin: 20px;
}

.infoIcon {
    margin-left: auto;
}

.itemDescription {
    margin-right: auto;
    max-width: 190px; /* Required in IE, otherwise longer texts move the action buttons more to the left/right */
}