/* Header.css */

.header {
    height: 70px;
    position: relative;
    width: 100%;
}

/*#logo {
    display: inline-block;
    height: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}*/

#logo {
    max-height: 40px;
    margin-right: 10px;
}

.toolbar {
    align-items: center;
    justify-content: center;
}

.headerVisual {
    display: flex;
    align-items: center;
}

#logoutIcon {
    color: white;
    display: inline-block;
    margin-left: auto;
    line-height: 1em;
    font-size: 1em;
}

#returnIcon {
    color: white;
    display: inline-block;
    left: 10%;
    margin-left: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1em;
    line-height: 1em;
    padding: 0;
}

#languageIcon {
    color: white;
    display: inline-block;
    line-height: 1em;
    font-size: 1em;
}

.menu {
    width: 150px;
}