#list { margin: 20px; }

#caption { margin-left: 3px; }

.foodContainer {
    text-align: left;
    padding: initial;
    text-transform: initial;
    margin-left: 20px;
}

.portionContainer {
    width: 30px; /*DH voor 0.25 porties*/
}

#button {
    height: 50px;
    margin: 10px;
    width: 80%;
}
 
.infoIcon {
    margin-left: auto;
}