.snackbar {
    align-items: center;
    display: flex;
    text-align: center;
}

.errorIcon { 
    margin-right: 20px;
}
.warningIcon {
    margin-right: 20px;
}
.successIcon { 
    margin-right: 20px;
}

.errorPage {
    margin: 20px;
}

.errorTxt {
    font-family: "Nunito", sans-serif;
    color: #999fa5;
    font-weight: 400;
    padding-top: 10px;
}

img {
    max-width: 100%;
}