/* MealMoment */

.paper {
    width: auto;
}

.tab { margin-top: 10px; }

.tabLabel {
    font-weight: 400;
    text-transform: none;
    width: 75px;
}

.noMealButton {
    height: 50px;
    margin-top: 20px;
    max-width: 370px;
    width: 100%;
}

    .noMealButton:hover {
        background-color: #6b0404;
    }

.foodButton {
    /*width: 335px; /*Made this fix. Because the default is 100%. That 's ok as long as the text in the button is the same for all buttons.*/
    /*width: -webkit-fill-available;*/
    width:100%;
    background-color: white;
    color: black;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(119, 83, 83, 0.36);
    height: 80px;
    justify-content: left;
    margin: 10px;
    overflow: hidden;
    padding: 0;
    text-align: left;
    text-transform: none;
    max-width: 370px;
}

.foodButton:hover{
    background-color: #d5d5d5;
}

@media screen and (max-width: 600px) {
    .foodButton {
        width: 335px;
    }

    .noMealButton {
        width: 335px;
    }
}

@media screen and (max-width: 350px) {
    .foodButton {
        width: 300px;
    }
    .noMealButton {
        width: 300px;
    }
}

    .foodButtonDisabled {
        filter: grayscale(100%);
        opacity: .6 !important;
        background-color: white !important;
        color: rgba(0, 0, 0, 0.6) !important;
        border: 1px solid rgba(0,0,0,.1)
    }

    .imageContainer {
        display: flex;
        height: 80px;
        margin-right: 20px;
        width: 80px;
        align-items: center;
    }

    Button svg {
        margin: 0;
        overflow: hidden;
        transform: scale(0.8);
    }

    .breakFast {
        transform: rotate(30deg) scale(0.8);
    }

    .navigateNextIcon {
        margin-left: auto;
        margin-right: 15px;
    }

    .title {
        font-size: 1.6rem;
        margin-bottom: 15px;
    }

    .loadingSpinner {
        margin-top: 20px;
    }

    .svg1 {
        fill: red;
    }

    #dialogNoPadding {
        padding: 0 !important;
    }

    #MuiDialog-paper {
        margin: 0 !important;
    }