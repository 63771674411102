/* Login page */
.loginContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    margin-top: 20px;
}
 
.welcomeMessage {
    margin-top: 5px;
    margin-bottom: 20px;
}
