#list { margin: 20px; }

#caption { margin-left: 3px; }

.foodContainer { margin-left: 20px; }

.portionContainer {
    width: 30px; /*DH voor 0.25 porties*/
}

#button {
    height: 50px;
    margin-top: 10px;    
    width: 100%;
}

.noMealButton {
    height: 50px;    
    margin-top: 10px;
    width: 100%;
}

    .noMealButton:hover {
        background-color: #6b0404;
    }

.testBlock {
    background-color: red;
    color: red;
    height: 500px;
}

.infoIcon {
    margin-left: auto;
}
